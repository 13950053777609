import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide, type SwiperClass, type SwiperProps } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import "swiper/css";
import { clsx } from 'clsx';
import Img from '@react/widget/Img';
import { H3 } from '@react/widget/Headline';

const btnbgs = {
  "white": "bg-white",
  "gray": "bg-primary hover:bg-primary-800 md:bg-gray-50 md:hover:bg-gray-100"
};
const arrowPositions = {
  "outside": "[&_button:nth-of-type(1)]:xl:-left-[88px] [&_button:nth-of-type(2)]:xl:-right-[88px]",
  "inside": "[&_button:nth-of-type(1)]:lg:left-0 [&_button:nth-of-type(2)]:lg:right-0"
};

interface Props extends SwiperProps {
  items: Array<{
    homeLogos: Array<Image>;
    subtitle: string;
    headline: string;
    context: string;
    homeIllustrations: Array<Image>;
  }>;
  className?: string;
  carouselName?: string;
  carouselNav?: boolean;
  carouselBtn?: boolean;
  btnbg?: keyof typeof btnbgs;
  arrowPosition?: keyof typeof arrowPositions;
  tabs?: React.ReactNode;
}

const Tabs: React.FC<Props> = ({ className, items = [], carouselName = "", carouselNav = false, carouselBtn = false, btnbg = "gray", arrowPosition = "outside", children, tabs, ...props }) => {
  const swiperRef = useRef<SwiperClass | null>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  const handleSlideChange = (index: number) => {
    if (swiperRef.current)
      swiperRef.current.slideTo(index);  // slideTo 方法实现滚动
    setSwiperIndex(index);
  };

  return (
    <div className="mx-auto border-t pt-12">
      <Swiper
        className={clsx("relative mb-8 md:mb-15", arrowPositions[arrowPosition], className)} {...props}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(e) => setSwiperIndex(e.activeIndex)}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        {items.map((_c, i) => (
          <SwiperSlide key={`tabs-${i}`}>
            <div className={"flex flex-col md:flex-row gap-8 justify-between items-center"}>
              <div>
                <Img className="mx-auto" src={_c.homeIllustrations[0]} />
              </div>
              <div className="md:w-1/2 text-lg space-y-6 context-format [&>ul]:space-y-2 [&>ul]:pl-4 [&>ul]:list-image-angle [&>ul>li]:pl-2">
                <H3 size="2xl">{_c.headline}</H3>
                <div className="[&_ul]:list-image-angle [&_ul]:pl-4 [&_ul>li]:pl-2 [&_ul]:space-y-3" dangerouslySetInnerHTML={{ __html: _c.context }} />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`mt-8 lg:mt-0 border-t flex flex-row justify-between [&>div]:p-3 [&>div]:max-sm:px-1.5 md:[&>div]:px-0 md:[&>div]:py-5 [&>div]:font-semibold`}>
        {items.map((o, i) => (
          <div key={`tabs-trigger-${i}`} className={`flex flex-col md:flex-row items-center text-base cursor-pointer gap-3 -mt-px border-t-2 ${(swiperIndex === i) ? 'border-red-500' : 'border-transparent'}`} onClick={() => handleSlideChange(i)}>
            <div className={`rounded-full bg-gray flex items-center justify-center min-w-[50px] w-[50px] h-[50px] ${(swiperIndex === i) ? 'bg-red-500' : ''}`}>
              <Img className="mx-auto w-[30px] h-[30px]" width={30} height={30} src={o.homeLogos[0]} />
            </div>
            <div className="hidden md:block font-bold">{o.subtitle}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;